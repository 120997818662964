* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  height: 100vh;
}
body {
  font: 300 13px/1.6 'Helvetica Neue', Helvetica, Arial;
  color: #333;
  background: url('../img/body_bg.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  transition: all .4s .3s ease-in;
  font-family: 'Open Sans', sans-serif;
}
body,html{
	min-height: 100vh;
	overflow: hidden;
}
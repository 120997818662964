.main {
  position: relative;
  width: 1000px;
  margin: 0 auto;
  margin-top: 130px;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.25);

}

.white-bg {
  min-height: 100px;
  background: #fff;
}

.construction-info {
  background: url('../img/construction.svg') no-repeat;
  height: 100px;
}

.page {
  background: #fff;
  padding: 45px 35px;

  p {
    line-height: 25px;
  }
}

.contact-item-6 {
  width: 49%;
  padding: 0 15px;
  display: inline-block;
}

.contact-item-12 {
  width: 100%;
  padding: 0 15px;
  display: inline-block;
}

.contact-item {
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-decoration: none;
  font-size: 28px;
  color: #666;
  margin-bottom: 40px;

  .icon {
    display: inline-block;
    background: url('../img/icon-address.png') no-repeat;
    width: 56px;
    height: 48px;
    margin-right: 15px;
    vertical-align: middle;
    background-size: contain;
  }

  .icon-phone {
    background: url('../img/icon-phone.png') no-repeat;
    background-size: contain;
  }

  .icon-email {
    background: url('../img/icon-mail.png') no-repeat;
    background-size: contain;
  }

  a {
    color: #666;
    line-height: 48px;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
  }
}

.see-you-soon {
  font-size: 40px;
  font-family: Raleway, sans-serif;
  font-weight: 300;
  text-align: center;
  display: block;
  margin-top: 30px;
}

footer {
  text-align: right;
  color: #222;

  strong {
    font-weight: 600;
  }
}

footer {
  width: 1000px;
  margin: 0 auto;
  padding-top: 10px;
}
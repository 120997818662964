.header {
  position: absolute;
  top: -60px;
  width: 100%;

  a {
    color: #000;
  }
}

.logo {
  width: 650px;
  margin: 0 auto;

  img {
    max-width: 20%;
    display: block;
    float: left;
  }
  h1, h2 {
    width: 79%;
    display: block;
    float: right;
    text-align: left;
    padding-left: 15px;
  }

  h1 {
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 56px;
    line-height: 30px;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 44px;
    line-height: 30px;
    margin-top: 20px;
  }
}
